import React from "react";
import { IoRemoveOutline } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";
import { useSpring, animated } from "react-spring";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 6000,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const CounterMainHome = () => {
  return (
    <div className="container">
      {/* <span style={{color:'#FC4243',paddingLeft: 'inherit'}}>WORK PROCESS<IoRemoveOutline size={30} style={{marginBottom:'5px'}} /></span> */}
      <h2 style={{ paddingLeft: "inherit" }}>SERVING CANADA WITH EXCELLENCE</h2>
      <Row className="mt-4 container-fluid">
        <Col xs={12} md={4}>
          <h1 style={{ fontWeight: "bolder", display: "flex" }}>
            <Number n={2000} /> +
          </h1>
          <h4 style={{ fontFamily: "Lato, sans-serif" }}>
            Satisfied Customers
          </h4>
          <p style={{ fontFamily: "Lato, sans-serif" }}>
            Thousands of people relocated across
            <br /> Canada easily through our exceptional
            <br /> service.
          </p>
          <div
            className="textmoniLine"
            style={{
              height: "100px",
              borderLeft: "1px solid #666",
              position: "relative",
              top: "-130px",
              right: "15px",
              float: "right",
            }}
          ></div>
        </Col>

        <Col xs={12} md={4}>
          <h1
            style={{ fontWeight: "bolder", color: "#FC4243", display: "flex" }}
          >
            <Number n={45} /> +
          </h1>
          <h4 style={{ fontFamily: "Lato, sans-serif" }}>
            45+ Delivery Centres
          </h4>
          <p style={{ fontFamily: "Lato, sans-serif" }}>
            Extensive delivery network ensures <br /> pickup, and delivery
            across Canada
            <br /> quickly
          </p>
          <div
            className="textmoniLine"
            style={{
              height: "100px",
              borderLeft: "1px solid #666",
              position: "relative",
              top: "-130px",
              right: "15px",
              float: "right",
            }}
          ></div>
        </Col>
        <Col xs={12} md={4}>
          <h1 style={{ fontWeight: "bolder", display: "flex" }}>
            <Number n={16} />
            M+
          </h1>
          <h4 style={{ fontFamily: "Lato, sans-serif" }}>KM per month</h4>
          <p style={{ fontFamily: "Lato, sans-serif" }}>
            Our Drivers log thousands of miles monthly for the fastest
            Canada-wide delivery. Best Moving Services in Alberta.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default CounterMainHome;
